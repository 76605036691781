import React from 'react';
import players from './players.json';
import coaches from './coaches.json';

const PrintView = () => {

    const managers = coaches.coaches.coach.map((c) => {
        const cleaned = ('' + c.phone).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        const phoneNumber = match ? '(' + match[1] + ')' + match[2] + '-' + match[3] : '';

        return (
            <tr id={c.id} className='printRow'>
                <td className='printCell'>{c.name}</td>
                <td className='printCell'>{c.role}</td>
                <td className='printCell'>{c.email}</td>
                <td className='printCell'>{phoneNumber}</td>
            </tr>
        );
    });


    const roster = players.players.player.map((p, index) => {
            const isPitcher = p.pitches ? "Y" : "N";
    
            let secondaryPos = '';
            p.secondary.forEach((pos, index) => {
                secondaryPos = `${pos}`;
                if (index < p.secondary.length - 1) {
                    secondaryPos += '/';
                }
            });
            if (isPitcher) {
                secondaryPos += '/' + p.throws + 'HP';
            }
            const cleaned = ('' + p.phone).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            const phoneNumber = match ? '(' + match[1] + ')' + match[2] + '-' + match[3] : '';
        
            const className = index % 2 === 0 ? 'printRow' : 'printRowAlt';
    
        return (
            <tr id={p.number} className={className}>
                <td className='printCell'>{p.number}</td>
                <td className='printCell'>{p.name}</td>
                <td className='printCell'>{p.gradYear}</td>
                <td className='printCell'>{p.highSchool}</td>
                <td className='printCell'>{p.height}"</td>
                <td className='printCell'>{p.weight}</td>
                <td className='printCell'>{p.bats}/{p.throws}</td>
                <td className='printCell'>{p.primary}/{secondaryPos}</td>
                <td className='printCell'>{p.email} / {phoneNumber}</td>
                <td className='printCell'>{p.twitter}</td>
                <td className='printCell'>{p.instagram}</td>
            </tr>
        );
    });

    return (
        <>
        <table>
            <thead className='printHead'>
                <th className='printHeader'>#</th>
                <th className='printHeader'>Name</th>
                <th className='printHeader'>Year</th>
                <th className='printHeader'>School</th>
                <th className='printHeader'>Ht</th>
                <th className='printHeader'>Wt</th>
                <th className='printHeader'>B/T</th>
                <th className='printHeader'>Positions</th>
                <th className='printHeader'>Contact</th>
                <th className='printHeader'>Twitter</th>
                <th className='printHeader'>Instagram</th>
            </thead>
            <tbody>
                {roster}
            </tbody>
        </table>
        <h4>Staff</h4>
                <table>
                <thead className='printHead'>
                    <th className='printHeader'>Name</th>
                    <th className='printHeader'>Role</th>
                    <th className='printHeader'>Email</th>
                    <th className='printHeader'>Phone</th>
                </thead>
                <tbody>
                    {managers}
                </tbody>
            </table>
            </>
    );

};

export default PrintView;