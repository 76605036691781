import React, { useMemo } from 'react';

const PlayerContact = ({player}) => {
    const { number, email, phone, twitter, instagram, highSchool, gradYear, height, weight } = player;
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    const phoneNumber = match ? '(' + match[1] + ')' + match[2] + '-' + match[3] : '';

    const socialMedia = useMemo(() => {
        let media = [];
        if (twitter) {
            media.push(<div className="twitter"><i class="fa-brands fa-twitter" /> <b>{twitter}</b></div>);
        }
        if (instagram) {
            media.push(<div className="instagram"><i class="fa-brands fa-instagram" /> <b>{instagram}</b></div>);
        }

        return media;
    });

    return (
        <div className="contact" id={number}>
            <div className="info">
                <span className="gradYear">{gradYear}</span>
                <span>-</span>
                <span className="highSchool">{highSchool}</span>                
            </div>            
            <div className="contactInfo">
                <div className="email"><i class="fa-regular fa-at"></i> <a href={`mailto:${email}`}>{email}</a></div>
                <div className="phone"><i class="fa-solid fa-phone" /> {phoneNumber}</div>
            </div>
            <div className="social">
                {socialMedia}
            </div>
        </div>
    );
};

export default PlayerContact;