import React, { useMemo } from 'react';
import Proptypes from 'prop-types';


const PlayerName = (props) => {
    const { player, onClick} = props;
    const { number, name, height, weight, bats, throws, primary, secondary, pitches } = player;
    const imgUrl = 'https://wildcatters.bvolante.com/images/' + number + '_thumb.jpeg';
    const positions = useMemo(() => {
        const isPitcher = pitches ? "Y" : "N";

        const secondaryPos = secondary.join("/");
        const pitcher = isPitcher ? "/" + throws + "HP" : '';
        return (
            `${primary}/${secondaryPos}${pitcher}` 
        );
    }, [pitches, primary, secondary, throws]);

    return (
        <div class="nameInfo" id={number} onClick={() => onClick('Player', player)}>
            <table>
                <tr>
                    <td style={{ width: '40px'}}><span className='jersey'>{number}</span></td>
                    <td style={{ width: '120px'}}><span className='img'><img src={imgUrl} alt={name} /></span></td>
                    <td style={{ width: '450px'}}>
                        <div className="name">
                            {name}
                        </div>
                        <div className='info'>
                            <span className="text-bold">{positions}</span>
                            <span> {height}"</span>
                            <span> {weight}lbs</span>
                            <span> {bats}/{throws}</span>
                        </div>
                    </td>
                </tr>    
            </table>
        </div>
    );
};

PlayerName.propTypes = {
    player: Proptypes.object.isRequired,
    onClick: Proptypes.func
}

export default PlayerName;