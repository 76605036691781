import React from 'react';
import PlayerName from './PlayerName';

const PlayerInfo = ({player}) => {
    const { number } = player;

    return (
        <div className="position" id={number}>
            <div>        
                <PlayerName player={player} />
            </div>
        </div>
    );
};

export default PlayerInfo;