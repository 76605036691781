import React, { useMemo } from 'react';
import PlayerContact from './PlayerContact';

const Player = ({ player}) => {
    const { number, name, primary, secondary, gradYear, throws, bats, pitches, height, weight, highSchool, email, phone, twitter, instagram } = player;
    const imgUrl = 'https://wildcatters.bvolante.com/images/' + number + '.jpeg';
    const socialMedia = useMemo(() => {
        let media = [];
        if (twitter) {
            media.push(<div className="twitter"><i class="fa-brands fa-twitter" /> <b>{twitter}</b></div>);
        }
        if (instagram) {
            media.push(<div className="instagram"><i class="fa-brands fa-instagram" /> <b>{instagram}</b></div>);
        }

        return media;
    });
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    const phoneNumber = match ? '(' + match[1] + ')' + match[2] + '-' + match[3] : '';
    const secPos = secondary.join(", ");

    return (
        <>
            <h2>{name}</h2>
            <table>
                <tr>
                    <td><img src={imgUrl} alt={name} style={{ maxHeight: '400px', maxWidth: '400px', minHeight: '300px', minWidth:'300px'}}/></td>
                    <td>
                        <div className='playerInfo'><b>Height:</b> {height}"</div>
                        <div className='playerInfo'><b>Weight:</b> {weight}</div>
                        <div className='playerInfo'><b>Primary Position:</b> {primary}</div>
                        <div className='playerInfo'><b>Secondary Position:</b> {secPos}</div>
                        <div><PlayerContact player={player}/></div>
                    </td>
                </tr>
            </table>
        </>
    );
};

export default Player;