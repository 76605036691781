import React from 'react';
import PropTypes from 'prop-types';
import PlayerContact from './PlayerContact';
import PlayerInfo from './Playerinfo';
import players from './players.json';
import './roster.css';


const Roster = (props) => {

    const roster = players.players.player.map((p) => {

        return (
            <tr id={p.number} className='player' onClick={() => props.onClick('Player', p)}>
                <td onClick={() => props.onClick('Player', p)}>
                    <PlayerInfo player={p} />
                </td>
                <td>
                    <PlayerContact player={p} />
                </td>
            </tr>
        );
    });

return (
    <>
    <table id="roster">
        <tbody>
                {roster}
        </tbody>
    </table>
</>
);
};

Roster.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default Roster;