import React, { useState, useCallback } from 'react';
import Roster from './Roster';
import Player from './Player';
import PrintView from './PrintView';

const Header = () => {
    const [page, setPage] = useState('Roster');
    const [player, setPlayer] = useState({});

    const handleClick = useCallback((value, person) => {
        setPage(value);
        if (player) {
            setPlayer(person);
        } else {
            setPlayer({});
        }
    }, []);

    let showPage = '';
    if (page === 'Player') {
        showPage = <Player player={player} />;
    } else if (page === 'Roster') {
        showPage = <Roster onClick={handleClick} />;
    } else if (page === 'Print') {
        showPage = <PrintView />;
    }

    return (
        <>
        <div className='header'>
            <span>
                <img src='https://wildcatters.bvolante.com/images/wildcatters.webp' />
            </span>
            <span>
                <h1>Houston Wildcatters Ambres</h1>
            </span>
            </div>
            <div>
                { page !== "Print" ? <button onClick={() => handleClick('Roster')}>Roster</button> : null}
                {/* { page === "Roster" ? <button onClick={() => handleClick('Print')}>Print</button> : null} */}
            </div>
        <div>
            {showPage}
        </div>
        </>
    );
};

export default Header;